import ApiService from "./config";

// Login
// export const LoginService = (body) => {
//   return ApiService.post("admin/login", body);
// };

// Dashbord
export const LoginService = (body) => {
  return ApiService.post("users/login", body);
};

export const LoginService2 = (body) => {
  return ApiService.post("users/login/verifyotp", body);
};

// Users  courses

export const UsersCoursesService = (userId, page) => {
  return ApiService.get(`/users/${userId}/courses/enrolled?Offset=${page}`);
};

//Enrolled Courses

export const EnrolledCoursesService = (userId, courseId, page) => {
  return ApiService.get(
    `users/${userId}/courses/${courseId}/enrolled/videos?Offset=${page}`
  );
};
