import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../pages/css/VideoPage.css";
import Odigos from "../../assets/Odigos.png";

const VideoPage = () => {
  const navigate = useNavigate();
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    const storedVideoURL = localStorage.getItem("videoURL");
    setVideoURL(storedVideoURL);

    // Restrict right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [videoURL]);

  return (
    <div className="video-container">
      <button onClick={() => navigate(-1)}>Back</button>
      <h1>Course Videos</h1>
      {videoURL ? (
        <>
          <video controls controlsList="nodownload">
            <source src={videoURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <img src={Odigos} alt="ODIGOS logo" />
        </>
      ) : (
        <p>There is no video URL here.</p>
      )}
    </div>
  );
};

export default VideoPage;
