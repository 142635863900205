import axios from "axios";
import React, { useEffect, useState } from "react";
import { LuView } from "react-icons/lu";
import "../pages/css/User.css";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../BaseURL";
import { NavLink } from "react-router-dom";
import { UsersCoursesService } from "../../Services/APIrequests";
import Pagination from "react-js-pagination";
const User = () => {
  const [userdata, setUserData] = useState([]);
  const [status, setStatus] = useState(0);
  const [offset, setOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  const viewData = (courseId) => {
    navigate(`/details/${courseId}`);
  };
  const getUser = async (offset) => {
    try {

      const response = await UsersCoursesService(userId, offset)
      const { data } = response;
      const { CoursesEnrolled, Offset, CourseCount, Status } = data;
      setUserData(CoursesEnrolled);
      setTotalCount(CourseCount);
      setOffset(Offset);
      console.log(CoursesEnrolled);

      if (Status === 1) {
        setStatus(1);
        if (CoursesEnrolled.length > 0) {
          const courseId = CoursesEnrolled[0].courseId;
          console.log(localStorage);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUser((activePage - 1) * itemsPerPage);
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    // console.log(pageNumber)
    setActivePage(pageNumber);
  };
  return (
    <div className="user_container">
      <h1>CoursesEnrolled Information</h1>
      <div className="table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>Course ID</th>
              <th>Course Name</th>
              <th>Description</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {userdata.map((course) => (
              <tr key={course.courseId}>
                <td>{course.courseId}</td>
                <td>{course.courseName}</td>
                <td>{course.description}</td>
                <td>
                  <NavLink to={`/details/${course.courseId}`}>
                    <i className="view-bt">
                      <LuView />
                    </i>
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalCount}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
};
export default User;
