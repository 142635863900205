import axios from "axios";
import React, { useEffect, useState } from "react";
import { RiVideoLine } from "react-icons/ri";
import "../pages/css/Cdetails.css";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../../BaseURL";
import { EnrolledCoursesService } from "../../Services/APIrequests";
import Pagination from "react-js-pagination";

const CourseDetails = () => {
  const { courseId } = useParams();
  const [offset, setOffset] = useState(0);
  const [course, setCourse] = useState([]);
  const [status, setStatus] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const userId = localStorage.getItem("userId");
  // const courseId=localStorage.getItem('courseId');
  const token = localStorage.getItem("Token");
  const getCourse = async () => {
    try {
      const response = await EnrolledCoursesService(userId, courseId, offset);
      const { data } = response;
      const { CourseVideos, Status, Offset, VideosCount } = data;
      setCourse(CourseVideos);
      setTotalCount(VideosCount)
      setOffset(Offset);

      if (Status === 1) {
        setStatus(1);
      }

    } catch (error) {
      console.log(error);
    };
  };
  const playVideo = (videoURL) => {
    localStorage.setItem("videoURL", videoURL);
    console.log(videoURL, "jgjhgvh")
    navigate(`/play`);
  };

  useEffect(() => {
    getCourse((activePage - 1) * itemsPerPage);
  }, [courseId, activePage]);

  const handlePageChange = (pageNumber) => {
    // console.log(pageNumber)
    setActivePage(pageNumber);
  };

  return (
    <div className="course-videos-container">
      <button onClick={() => navigate(-1)}>Back</button>
      <h1>Course Videos</h1>
      <div className="table-container">
        <table className="videos-table">
          <thead>
            <tr>
              <th>Index</th>
              <th>CourseName</th>
              <th>TopicName</th>
              <th>VideoURL</th>
            </tr>
          </thead>
          <tbody>
            {course.map((video, index) => (
              <tr key={video.videoId}>
                <td>{(activePage - 1) * itemsPerPage + index + 1}</td>
                <td>{video.courseName}</td>
                <td>{video.topicName}</td>
                <td>
                  <button type="button" onClick={() => playVideo(video.videoURL)}>
                    <RiVideoLine />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalCount}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
};
export default CourseDetails;
